<template>
  <div class="row journal-line border-rad-8">
    <div class="col-12 d-flex product-details-border position-relative pe-0">
      <div class="row w-100 pe-lg-0 me-1 py-2">
        <div class="col-lg-5 col-12 mt-1">
          <v-select
              placeholder="Select Product / Service"
              v-model="item.product_id"
              :options="products"
              label="product"
              :reduce="product => product.id"
          />
        </div>
        <div class="col-lg-2 col-6 mt-1">
          <input
              v-model="item.qty"
              type="number"
              class="form-control text-end"
              placeholder="Qty"
          >
        </div>
        <div class="col-lg-2 col-6 mt-1">
          <input
              v-model="item.rate"
              type="number"
              class="form-control text-end"
              placeholder="Rate"
          >
        </div>
        <div class="col-lg-2 col-6 mt-1">
          <input
              :value="item.amount"
              type="number"
              readonly
              class="form-control text-end"
              placeholder="Amount"
          >
        </div>
        <div class="col-lg-5 col-12 mt-1">
          <v-select
              v-model="item.vat_type"
              :options="vatTypes"
              label="type"
              :reduce="type => type.id"
          />
        </div>
        <div class="col-lg-2 col-6 mt-1">
          <input
              :value="item.vat_amount"
              type="number"
              readonly
              class="form-control text-end"
              placeholder="Vat Amount"
          >
        </div>
        <div class="col-lg-2 col-6 mt-1">
          <input
              :value="item.total_amount"
              type="number"
              readonly
              class="form-control text-end"
              placeholder="Total Amount"
          >
        </div>
        <div class="col-lg-2 col-12 mt-1">
          <textarea
              v-model="item.note"
              placeholder="Description"
              class="form-control"
              rows="1"
          />
        </div>
      </div>

      <div
          @click="$emit('onClose', index)"
          class="d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-x cursor-pointer font-medium-3">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AddItemDetails',
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    products: {
      type: Array
    },
    vatTypes: {
      type: Array
    }
  }
}
</script>
<style scoped>
.journal-line {
  border: 1px solid #f0f2f5;
}

.journal-line:hover {
  background: #f0f2f5;
}
</style>
