<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="Add New Requisition"
          @onClickAddNewButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Requester</label>
            <input type="text" class="form-control" v-model="formData.requester">
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Requisition No</label>
            <input type="text" class="form-control" v-model="formData.requisition_no">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Department</label>
            <input type="text" class="form-control" v-model="formData.department">
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Requisition Date</label>
            <input type="date" class="form-control" v-model="formData.requisition_date">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Project</label>
            <v-select
                placeholder="Select Project"
                v-model="formData.project_id"
                :options="projects"
                label="project"
                :reducer="project=> project.id"
            />
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Project Budget Ref</label>
            <input type="text" class="form-control" v-model="formData.project_budget_ref">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Business</label>
            <input type="text" class="form-control" v-model="formData.business">
          </div>
          <div class="col-sm-8"></div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-2">
      <AddItemDetails
          class="mb-2"
          :products="products"
          v-for="(item, index) in formData.item_details"
          :key="index"
          :index="index"
          :item="item"
          @onClose="onClose"
      />
    </div>
    <div class="row me-1 mt-3 px-2">
      <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
      </div>
    </div>

    <div class="p-1">
      <div class="row mt-3 px-2">
        <div class="col-md-7 row justify-content-center">

          <div class="col-lg-7 col-12 text-center border-dashed">
            <div class="d-flex flex-wrap justify-content-between align-middle">
              <BlobImageViewer
                  v-for="(file, index) in formData.additional_images"
                  :file="file"
                  :key="index"
                  :index="index"
              >
                <template v-slot:handler>
                  <img
                      class="position-absolute cursor-pointer top-0"
                      width="15"
                      src="/app-assets/images/icons/trash.svg"
                      alt=""
                  >
                </template>
              </BlobImageViewer>
            </div>
            <div class="pt-2">
              <div>
                <!--TODO: Setup bind functions and remove hidden attribute from input-->
                <input hidden>
                <p>Drop the files here ...</p>
                <p>Drag 'n' drop some additional images</p>
              </div>
              <button @click.prevent="open" class="btn btn-outline-primary">open</button>
            </div>
          </div>

        </div>
        <div class="col-md-5">
          <div class="row mt-1">
            <div class="col-md-4 align-middle">SubTotal :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="formData.sub_total" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">VAT :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="formData.vat" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">Total :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="formData.total" readonly>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea v-model="formData.memo" placeholder="Memo" class="form-control" rows="2" id="memo"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {inject, ref} from 'vue'
import {useRoute, useRouter} from "vue-router"
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/requisition/AddItemDetails"
import AddButton from "@/components/atom/AddButton"
import BlobImageViewer from '@/components/atom/BlobImageViewer'

const router = useRouter()
const route = useRoute()
const showError = inject('showError')
const showSuccess = inject('showSuccess')

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let projects = ref([])
let products = ref([])
let formData = ref({
  requester: null,
  requisition_no: null,
  department: null,
  requisition_date: null,
  project_id: null,
  project_budget_ref: null,
  business: null,
  sub_total: 0.00,
  vat: 0.00,
  total: 0.00,
  additional_images: [],
  item_details: [
    {
      product_id: null,
      qty: null,
      rate: null,
      amount: null,
      vat_type: null,
      vat_amount: null,
      total_amount: null,
      note: null
    }
  ],
})

function navigateToListPage() {
  router.push({name: `requisition-list`, params: route.params, query: route.query});
}

function handleSubmit() {

}

function onClose(index) {
  formData.value.item_details.splice(index, 1)
}

function onClickAdd() {
  formData.value.item_details.push({
    product_id: null,
    consumption_qty: null,
    consumption_rate: null,
    consumption_amount: null,
    vat_type: null,
    vat_amount: null,
    total_amount: null,
    note: null
  });
}
</script>